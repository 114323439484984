import React from 'react';
import { useWindowDimensions } from '../../../assets/helpers/useWindowDimensions';
import styles from './InfoPanel.module.css';

const InfoPanel = ({ Icon = null, title = '', text = '', children, buttons = null, stickyColumnCount = 2 }) => {
  const { columnCount } = useWindowDimensions();

  const wrapperClass = `${styles.wrapper} ${buttons ? styles.hasButtons : ''} ${
    columnCount >= stickyColumnCount ? styles.userInfoSticky : ''
  }`;

  return (
    <div className={wrapperClass}>
      <div className={styles.contentContainer}>
        {Icon ? <Icon className={styles.icon} /> : null}
        <div className={styles.contentWrapper}>
          {title ? <span className={styles.title}>{title}</span> : null}
          <div
            dangerouslySetInnerHTML={{
              __html: text
            }}
            className={styles.text}
          />
        </div>
        {children}
      </div>
      {buttons ? buttons : null}
    </div>
  );
};

export default InfoPanel;
